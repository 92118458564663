import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/Users/StephanVolklandANYME/Desktop/gitProjects/customerDocumentation/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "check-system-status"
    }}>{`Check system status`}</h2>
    <p>{`You can check our current system status via http.`}</p>
    <p>{`Visit `}<a parentName="p" {...{
        "href": "https://status.anymessage.cloud/status"
      }}>{`https://status.anymessage.cloud/status`}</a>{` for uptime and maintenance notifications.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      